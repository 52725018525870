/* reset */

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

fieldset,
img,
abbr {
	border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-weight: normal;
	font-style: normal;
}

ul li {
	list-style: none;
}

caption,
th {
	text-align: left;
}

button {
	border: none;
	background: none;
	padding: 0;
}

button:hover {
	cursor: pointer;
}

a {
	text-decoration: none;
}

/* /reset */
