.preloader-wrapper {
	display: flex;
	min-height: 100vh;
}

.preloader {
	display: flex;
	flex: auto;
	align-items: center;
	justify-content: center;
	background-color: rgb(255, 255, 255);
}

.windows8 {
	position: relative;
	width: 50vmin;
	height: 50vmin;
	margin: 100px auto;
}

.windows8 .wBall {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	transform: rotate(225deg);
	-o-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	-webkit-transform: rotate(225deg);
	-moz-transform: rotate(225deg);
	animation: orbit 4.2325s infinite;
	-o-animation: orbit 4.2325s infinite;
	-ms-animation: orbit 4.2325s infinite;
	-webkit-animation: orbit 4.2325s infinite;
	-moz-animation: orbit 4.2325s infinite;
}

.windows8 .wBall .wInnerBall {
	position: absolute;
	width: 12.5%;
	height: 12.5%;
	background: var(--color-orange);
	left: 0px;
	top: 0px;
	border-radius: 30px;
}

.windows8 #wBall_1 {
	animation-delay: 0.926s;
	-o-animation-delay: 0.926s;
	-ms-animation-delay: 0.926s;
	-webkit-animation-delay: 0.926s;
	-moz-animation-delay: 0.926s;
}

.windows8 #wBall_2 {
	animation-delay: 0.183s;
	-o-animation-delay: 0.183s;
	-ms-animation-delay: 0.183s;
	-webkit-animation-delay: 0.183s;
	-moz-animation-delay: 0.183s;
}

.windows8 #wBall_3 {
	animation-delay: 0.3665s;
	-o-animation-delay: 0.3665s;
	-ms-animation-delay: 0.3665s;
	-webkit-animation-delay: 0.3665s;
	-moz-animation-delay: 0.3665s;
}

.windows8 #wBall_4 {
	animation-delay: 0.5495s;
	-o-animation-delay: 0.5495s;
	-ms-animation-delay: 0.5495s;
	-webkit-animation-delay: 0.5495s;
	-moz-animation-delay: 0.5495s;
}

.windows8 #wBall_5 {
	animation-delay: 0.743s;
	-o-animation-delay: 0.743s;
	-ms-animation-delay: 0.743s;
	-webkit-animation-delay: 0.743s;
	-moz-animation-delay: 0.743s;
}

@keyframes orbit {
	0% {
		opacity: 1;
		z-index: 99;
		transform: rotate(180deg);
		animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		transform: rotate(300deg);
		animation-timing-function: linear;
		origin: 0%;
	}

	30% {
		opacity: 1;
		transform: rotate(410deg);
		animation-timing-function: ease-in-out;
		origin: 7%;
	}

	39% {
		opacity: 1;
		transform: rotate(645deg);
		animation-timing-function: linear;
		origin: 30%;
	}

	70% {
		opacity: 1;
		transform: rotate(770deg);
		animation-timing-function: ease-out;
		origin: 39%;
	}

	75% {
		opacity: 1;
		transform: rotate(900deg);
		animation-timing-function: ease-out;
		origin: 70%;
	}

	76% {
		opacity: 0;
		transform: rotate(900deg);
	}

	100% {
		opacity: 0;
		transform: rotate(900deg);
	}
}

@-o-keyframes orbit {
	0% {
		opacity: 1;
		z-index: 99;
		-o-transform: rotate(180deg);
		-o-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-o-transform: rotate(300deg);
		-o-animation-timing-function: linear;
		-o-origin: 0%;
	}

	30% {
		opacity: 1;
		-o-transform: rotate(410deg);
		-o-animation-timing-function: ease-in-out;
		-o-origin: 7%;
	}

	39% {
		opacity: 1;
		-o-transform: rotate(645deg);
		-o-animation-timing-function: linear;
		-o-origin: 30%;
	}

	70% {
		opacity: 1;
		-o-transform: rotate(770deg);
		-o-animation-timing-function: ease-out;
		-o-origin: 39%;
	}

	75% {
		opacity: 1;
		-o-transform: rotate(900deg);
		-o-animation-timing-function: ease-out;
		-o-origin: 70%;
	}

	76% {
		opacity: 0;
		-o-transform: rotate(900deg);
	}

	100% {
		opacity: 0;
		-o-transform: rotate(900deg);
	}
}

@-ms-keyframes orbit {
	0% {
		opacity: 1;
		z-index: 99;
		-ms-transform: rotate(180deg);
		-ms-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-ms-transform: rotate(300deg);
		-ms-animation-timing-function: linear;
		-ms-origin: 0%;
	}

	30% {
		opacity: 1;
		-ms-transform: rotate(410deg);
		-ms-animation-timing-function: ease-in-out;
		-ms-origin: 7%;
	}

	39% {
		opacity: 1;
		-ms-transform: rotate(645deg);
		-ms-animation-timing-function: linear;
		-ms-origin: 30%;
	}

	70% {
		opacity: 1;
		-ms-transform: rotate(770deg);
		-ms-animation-timing-function: ease-out;
		-ms-origin: 39%;
	}

	75% {
		opacity: 1;
		-ms-transform: rotate(900deg);
		-ms-animation-timing-function: ease-out;
		-ms-origin: 70%;
	}

	76% {
		opacity: 0;
		-ms-transform: rotate(900deg);
	}

	100% {
		opacity: 0;
		-ms-transform: rotate(900deg);
	}
}

@-webkit-keyframes orbit {
	0% {
		opacity: 1;
		z-index: 99;
		-webkit-transform: rotate(180deg);
		-webkit-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-webkit-transform: rotate(300deg);
		-webkit-animation-timing-function: linear;
		-webkit-origin: 0%;
	}

	30% {
		opacity: 1;
		-webkit-transform: rotate(410deg);
		-webkit-animation-timing-function: ease-in-out;
		-webkit-origin: 7%;
	}

	39% {
		opacity: 1;
		-webkit-transform: rotate(645deg);
		-webkit-animation-timing-function: linear;
		-webkit-origin: 30%;
	}

	70% {
		opacity: 1;
		-webkit-transform: rotate(770deg);
		-webkit-animation-timing-function: ease-out;
		-webkit-origin: 39%;
	}

	75% {
		opacity: 1;
		-webkit-transform: rotate(900deg);
		-webkit-animation-timing-function: ease-out;
		-webkit-origin: 70%;
	}

	76% {
		opacity: 0;
		-webkit-transform: rotate(900deg);
	}

	100% {
		opacity: 0;
		-webkit-transform: rotate(900deg);
	}
}

@-moz-keyframes orbit {
	0% {
		opacity: 1;
		z-index: 99;
		-moz-transform: rotate(180deg);
		-moz-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-moz-transform: rotate(300deg);
		-moz-animation-timing-function: linear;
		-moz-origin: 0%;
	}

	30% {
		opacity: 1;
		-moz-transform: rotate(410deg);
		-moz-animation-timing-function: ease-in-out;
		-moz-origin: 7%;
	}

	39% {
		opacity: 1;
		-moz-transform: rotate(645deg);
		-moz-animation-timing-function: linear;
		-moz-origin: 30%;
	}

	70% {
		opacity: 1;
		-moz-transform: rotate(770deg);
		-moz-animation-timing-function: ease-out;
		-moz-origin: 39%;
	}

	75% {
		opacity: 1;
		-moz-transform: rotate(900deg);
		-moz-animation-timing-function: ease-out;
		-moz-origin: 70%;
	}

	76% {
		opacity: 0;
		-moz-transform: rotate(900deg);
	}

	100% {
		opacity: 0;
		-moz-transform: rotate(900deg);
	}
}
