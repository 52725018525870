:root {
    --color-orange: #EE7526;
    --color-orange-dark: #813707;
    --color-red: #EA2828;
    --color-dark: #222222;
    --color-gray: #333333;
    --color-gray-2: #4F4F4F;
    --color-green: #27AE60;
    --color-blue: #378CBD;
}

.flex-break {
  width: 100%;
}

/* components */
a.link-dark, button.link-dark, span.link-dark {
    border-bottom: 1px solid var(--color-dark);
    color: var(--color-dark);
    cursor: pointer;
}

    a.link-dark:hover button.link-dark:hover, a.link-dark:active {
        text-decoration: none;
        border-bottom: 1px solid var(--color-orange);
        color: var(--color-orange);
    }

a.link-dark:visited {
  border-bottom: 1px solid var(--color-orange-dark);
  color: var(--color-orange-dark);
}

a.link-orange-old-cabinet {
    border-bottom: 1px solid var(--color-orange);
    color: var(--color-orange) !important;
    font-weight: 800 !important;
}

a.link-orange {
    border-bottom: 1px solid var(--color-orange);
    color: var(--color-orange);
}

a.link-orange-dark {
  border-bottom: 1px solid var(--color-orange-dark);
  color: var(--color-orange-dark);
}

a.link-orange:hover, a.link-orange:active, a.link-orange-dark:hover, a.link-orange-dark:active {
  border-bottom: 1px solid var(--color-dark);
  color: var(--color-dark);
}

a.link-orange:visited, a.link-orange-dark:visited {
  border-bottom: 1px solid var(--color-orange-dark);
  color: var(--color-orange-dark);
}

button.link-orange {
    /*border-bottom: 1px solid var(--color-orange);*/
    color: var(--color-orange);
}

button.link-orange-dark {
    /*border-bottom: 1px solid var(--color-orange-dark);*/
    color: var(--color-orange-dark);
}

    button.link-orange:hover, button.link-orange:active, button.link-orange-dark:hover, button.link-orange-dark:active {
        /*border-bottom: 1px solid var(--color-dark);*/
        color: var(--color-dark);
    }

a.link-blue {
    color: var(--color-blue);
}

a.link-blue:hover, a.link-blue:active {
  color: var(--color-orange);
}

a.link-blue:visited {
  color: var(--color-orange-dark);
}

a.link-gray, button.link-gray {
  border-bottom: 1px solid var(--color-gray-2);
  color: var(--color-gray-2);
}

    a.link-gray:hover, a.link-gray:active,
    button.link-gray:hover{
        border-bottom: 1px solid var(--color-orange);
        color: var(--color-orange);
    }

a.link-gray:visited {
  /* color: var(--color-orange-dark); */
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold !important;
}

.text-orange {
  color: var(--color-orange) !important;
}

.text-red {
    color: var(--color-red) !important;
}

.width-100 {
  width: 100%;
}

.mt-0 {
  margin-top: 0;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.hidden {
  display: none !important;
}

.desktop-hidden {
  display: none;
}

.desktop-only {
  display: block;
}

.tablet-only {
  display: none;
}

.mobile-only {
  display: none;
}

.table {
  display: table;
}

.table-row {
  display: table-row
}

.table-col {
  display: table-cell
}

/* radio toggle */

.radio-toggle {
  width: 144px;
  display: inline-flex;
  border: 1px solid rgba(238, 117, 38);
  box-sizing: border-box;
  /* box-shadow: inset 0px 16px 24px rgba(0, 0, 0, 0.06), inset 0px 2px 6px rgba(0, 0, 0, 0.04), inset 0px 0px 1px rgba(0, 0, 0, 0.04); */
  /* filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06)), drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)), drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04)); */
  border-radius: 4px;
  background-color: #FFFFFF;
}

.toggle-item {
  width: 50%;
  height: 32px;
  cursor: pointer;
}

.toggle-item .toggle-item-name {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
}

.toggle-item input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.toggle-item input[type=radio]:checked+.toggle-item-name {
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(238, 117, 38, 0.8), rgba(238, 117, 38, 0.8)), #FFFFFF;
  box-shadow: 0px 2px 6px rgba(238, 117, 38, 0.04);
  color: #FFFFFF;
}

/* checkbox */

.checkbox {
  display: inline-block;
  cursor: pointer;
}

.checkbox input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: auto;
}

.checkbox-item-name {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}

    .checkbox-item-name::before {
        content: '';
        display: inline-block;
        min-width: 18px;
        min-height: 18px;
        border: 2px solid #222222;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 12px;
    }

.checkbox input[type="checkbox"]:checked + .checkbox-item-name::before {
    border-color: var(--color-orange);
    background-image: url("../img/svg/Tick\ Square.svg");
    background-size: 20px 20px;
    background-position: center;
}

.checkbox-small .checkbox-item-name::before {
    width: 16px;
    height: 16px;
    border: 1px solid #C4C4C4;
}

.checkbox-small input[type="checkbox"]:checked + .checkbox-item-name::before {
    background-size: 20px 20px;
}

.modals-checkbox {
    display: flex;
    cursor: pointer;
}

    .modals-checkbox input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: auto;
    }

.modals-checkbox-item-name {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    margin-right: 8px;
}

    .modals-checkbox-item-name::before {
        content: '';
        display: inline-block;
        min-width: 18px;
        min-height: 18px;
        border: 2px solid #222222;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 6px;
    }

.modals-checkbox input[type="checkbox"]:checked + .modals-checkbox-item-name::before {
    border-color: var(--color-orange);
    background-image: url("../img/svg/Tick\ Square.svg");
    background-size: 20px 20px;
    background-position: center;
}

.modals-checkbox-small .modals-checkbox-item-name::before {
    width: 16px;
    height: 16px;
    border: 1px solid #C4C4C4;
}

.modals-checkbox-small input[type="checkbox"]:checked + .modals-checkbox-item-name::before {
    background-size: 20px 20px;
}

/* radio */

.radio-item {
  display: inline-block;
  cursor: pointer;
}

.radio-item input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.radio-item-name {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 13px;
  font-weight: normal;
}

.radio-item-name::before {
  content: '';
  display: inline-block;
  min-width: 20px;
  height: 20px;
  margin-right: 12px;
  border: 2px solid #222222;
  border-radius: 50%;
  box-sizing: border-box;
}

.radio-item input[type="radio"]:checked+.radio-item-name::before {
  border: 7px solid var(--color-orange);
}

.radio-item-name.new-orders {
    color: var(--color-orange);
}

    .radio-item-name.new-orders::before {
        border: 2px solid var(--color-orange);
    }


.radio-item-name.rework {
    color: var(--color-blue);
}

    .radio-item-name.rework::before {
        border: 2px solid var(--color-blue);
    }

/* file input */

.file-input input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    max-width: 100px;
}

.file-input-label .button {}

.file-input-title {
  margin-left: 16px;
  display: inline-flex;
  align-items: center;
}

.file-input-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.file-input-cancel {
  display: inline-block;
  margin-left: 8px;
  width: 8px;
  height: 8px;
  background-image: url(../img/svg/file-cancel.svg);
  background-size: 8px 8px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

/* select */
select {
    height: 32px;
    padding: 4px 12px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

/* modal */

body.modal-opened {
  overflow-y: hidden;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 60px 0;
  box-sizing: border-box;
  background: rgba(51, 51, 51, .4);
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  overflow-y: auto;
  z-index: 9999;
  /*backdrop-filter: blur(4px);*/
}

.modal-wrapper.open {
    display: block;
}

.modal-wrapper.close {
    display: none;
}

.modal-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
    position: relative;
    width: 100%;
    max-width: 918px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 40px 40px 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
}

.modal-report {
    position: relative;
    width: 80%;
    max-width: 540px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 30px 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
}

    .modal-report label {
        display: block;
        margin-bottom: 10px;
    }


.modal.open {
    display: block;
}

.modal.close {
    display: none;
}

.modal h2 {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
}

.modal p {
  margin-top: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.modal ol {
    margin-left: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.modal-signup {
    max-width: 420px;
    height: auto;
    display: block;
}

.modal-title {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.modal-subtitle {
  max-width: 266px;
  margin: 16px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-dark);
  opacity: 0.6;
}

.form-hr {
  margin-left: -16px;
  margin-right: -16px;
  height: 1px;
  background-color: #F2F2F2;
}

.modal-form {
  position: relative;
  margin-top: 16px;
}
    
.form-item {
  margin-bottom: 20px;
}

.form-signup .form-item {
  margin-bottom: 26px;
}

.form-item > label {
    display: inline-block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(33, 33, 33, .6);
}

.form-item input, .form-item textarea, .form-item select, .form-control {
    width: 100%;
    height: 40px;
    border: 1px solid #E0E0E0;
    padding: 10px 16px 10px 16px;
    box-sizing: border-box;
    outline: none;
    border-radius: 2px;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-dark);
    background-color: white;
}

    .form-control.form-control-small {
        height: 28px;
        padding: 6px 12px 5px 12px;
        border-radius: 4px;
        font-size: 14px;
    }

.form-item input:focus, .form-item textarea:focus {
  /* border: 1px solid var(--color-dark); */
  border: 1px solid rgba(34, 34, 34, .8);
}

    .form-item input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
        /* Chrome */
        color: #000000;
        opacity: 0.3;
        font-size: 14px;
    }

    .form-item input:-ms-input-placeholder, .form-control:-ms-input-placeholder {
        /* IE 10+ */
        color: #000000;
        opacity: 0.3;
        font-size: 14px;
    }

    .form-item input::-moz-placeholder, .form-control::-moz-placeholder {
        /* Firefox 19+ */
        color: #000000;
        opacity: 0.3;
        font-size: 14px;
    }

    .form-item input:-moz-placeholder, .form-control:-moz-placeholder {
        /* Firefox 4 - 18 */
        color: #000000;
        opacity: 0.3;
        font-size: 14px;
    }

.form-group {
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
}

    .form-group .form-item {
        width: 100%;
        max-width: 50%;
        margin-left: 16px;
        margin-right: 16px;
        max-width: 100%;
        flex-grow: 1;
    }

.form-signup .form-checkbox {
  margin-top: 30px;
  margin-bottom: 30px;
}

.form-checkbox {
  position: relative;
  display: flex;
  cursor: pointer;
}

label.form-checkbox {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(33, 33, 33, .6);
}

label.form-checkbox:hover, label.form-checkbox:focus {
  opacity: 1;
}

.form-checkbox input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form-checkbox-indicator {
  position: relative;
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 12px;
  box-sizing: border-box;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: transparent;
}

.form-checkbox-indicator::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 4px;
  top: 5px;
  left: 4px;
  box-sizing: border-box;
  border-bottom: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  transform: rotate(-45deg);
  opacity: 0;
}

.form-checkbox input[type="checkbox"]:checked+.form-checkbox-indicator {
  border: none;
  background-color: var(--color-orange);
}

.form-checkbox input[type="checkbox"]:checked+.form-checkbox-indicator::after {
  opacity: 1;
}

.form-checkbox a {
  text-decoration: none;
  font-weight: bold;
  color: rgb(15, 97, 144, .6);
}

.button {
    display: flex;
    padding: 11px 24px 10px 24px;
    min-height: 41px;
    box-sizing: border-box;
    border: 1px solid transparent;
    outline: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.button-open-dismissal-request {
    color: var(--color-red);
    text-decoration: underline;
}

.button-open-dismissal-request:hover {
    text-decoration: none;
}

.button.button-small {
    padding: 4px 20px 4px 20px;
    min-height: 27px;
    font-weight: 500;
    font-size: 12px;
}

.button.button-orange {
  background-color: var(--color-orange);
  border: 2px solid transparent;
  color: #FFFFFF;
}

.button.button-orange:focus {
  /* border: 2px solid rgba(34, 34, 34, 0.5); */
  border: 2px solid #878787;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.button.button-orange:hover {
  text-decoration: none;
  background: var(--color-orange);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.button.button-orange:active {
  background: var(--color-dark);
}

.button.button-orange:disabled {
  opacity: 0.5;
}

.button.button-dark {
  background-color: var(--color-dark);
  color: #FFFFFF;
}

.button.button-dark:focus {
  /* border: 2px solid rgba(34, 34, 34, 0.5); */
  border: 1px solid rgba(238, 117, 38, 0.8);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.button.button-dark:hover {
  background: var(--color-dark);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.button.button-dark:active {
  background: var(--color-orange);
}

.button.button-dark:disabled {
  opacity: 0.5;
}

.button-transparent {
  border: 1px solid var(--color-orange);
}

.button-transparent:focus {
  border: 1px solid #878787;
}

.button-transparent:active {
  border: 1px solid var(--color-dark);
  background-color: var(--color-dark);
  color: #FFFFFF;
}

.button-group {
    display: flex;
  margin-left: -8px;
  margin-right: -8px;
}

.button-group .button {
  margin-left: 8px;
  margin-right: 8px;
}

    .button-group.first-entry {
        justify-content: center;
    }

    .form-signup .button {
        max-width: 170px;
    }

.form-button {
  text-align: center;
}

.modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 100;
  cursor: pointer;
}

.modal-close::after {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url('../img/svg/close.svg');
  background-size: 18px 18px;
  background-repeat: no-repeat;
}

    .modal-close:hover::after, .modal-close:focus::after {
        background-image: url('../img/svg/close-active.svg');
    }

.form-error {
  display: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-red);
}

.from-item-error .form-error {
  display: block;
}

.from-item-error label {
  color: var(--color-red);
}

.from-item-error input {
  border-color: var(--color-red);
}

.modal-signup-thanks {
    max-width: 646px;
    height: auto;
    display: block;
}

.modal-signup-thanks p {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  opacity: 0.6;
}

/* start-page */

.start-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../img/login-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.start-page .logo {
    display: none;
}

.start-page h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 16px;
}

    .start-page h1 a{
        color: black;
        text-decoration: none;
    }

.start-page h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 48px;
}

.modal-login {
  display: block;
  max-width: 436px;
}

.forgot-password-link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.6;
  padding-bottom: 2px;
}

.registration-link {
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.6;
    padding-bottom: 2px;
}

.form-login .form-button {
  margin-top: 26px;
}

.form-login .form-button button {
  width: 100%;
}

/* forgot password */

.modal-forgot {
  max-width: 436px;
}

.form-forgot-password .form-button {
  margin-top: 32px;
}

.form-forgot-password .form-button button {
  width: 100%;
}

.modal-forgot-success {
  display: none;
  max-width: 625px;
}

.modal-forgot-success .modal-subtitle {
  font-size: 18px;
  line-height: 28px;
  max-width: 300px;
}

.modal-forgot-success .form-forgot-email {
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.modal-forgot-success .form-button .button {
  max-width: 355px;
  width: 100%;
}

.modal-forgot-success .form-redirect {
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: var(--color-dark);
  opacity: 0.6;
}

/* set password */

.pass-validation {
  margin-top: 8px;
  text-align: center;
}

.pass-validation-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.pass-validation-list {
  margin-top: 8px;
  margin-left: calc(50% - 30px);
}

.pass-validation-item {
  position: relative;
  margin-bottom: 6px;
  padding-left: 18px;
  color: rgba(34, 34, 34, .6);
  font-weight: 500;
  font-size: 12px;
  text-align: left;
}

.pass-validation-item::before {
  content: '';
  position: absolute;
  left: 2px;
  top: 5px;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #333333;
}

.pass-validation-item.pass-valid {
  color: var(--color-green);
}

.pass-validation-item.pass-valid::before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  display: block;
  width: 9px;
  height: 7px;
  border: none;
  background-color: transparent;
  background-image: url(../img/svg/valid-marker.svg);
  background-size: 9px 7px;
  background-repeat: no-repeat;
}

/* header */

.main-header {
/*  position: fixed;
  left: 285px;
  right: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  min-height: 98px;
  background: #FFFFFF;*/
}

.header-nav {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-left: 38px;
  padding-right: 44px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.header-burger {
  flex-grow: 1;
  display: none;
}

.menu-burger {
    display: inline-block;
    width: 21px;
    height: 14px;
    margin-right: auto;
    background-image: url(../img/svg/menu-burger.svg);
    background-size: 21px 14px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.header-search {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.header-search-btn {
    display: inline-flex;
    cursor: pointer;
}

.search-control {
    width: 0;
    position: relative;
    display: flex;
    overflow: hidden;
}

.search-input {
    width: 100%;
    height: 40px;
    padding: 0;
    padding: 6px 30px 6px 44px;
    box-sizing: border-box;
    border: none;
    background: rgba(34, 34, 34, 0.03);
    border-radius: 20px;
    font-size: 14px;
    color: #222222;
    background-image: url(../img/svg/search-input.svg);
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: left center;
    background-position-x: 16px;
    transition: width 0.3s cubic-bezier(0, 0.8, 0, 1);
}

    .search-input::-webkit-input-placeholder, .search-input:-ms-input-placeholder, .search-input::-moz-placeholder, .search-input:-moz-placeholder {
        color: rgba(197, 64, 64, 0.5);
        opacity: 1;
    }

.search-close {
    display: none;
    position: absolute;
    right: 16px;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    background-image: url(../img/svg/search-close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px 12px;
    opacity: 0;
    cursor: pointer;
    transition: opacity .3s ease-out .3s;
}

.search-active .search-control {
    width: 100%;
    max-width: 369px;
}

.search-active .header-search-btn {
    display: none;
}

.search-active .search-input {
    /* width: 100%; */
    /* padding: 6px 30px 6px 44px; */
}

.search-active .search-close {
    display: inline-flex;
    opacity: 1;
}

.saved-students {
    display: flex;
}

    .saved-students.search-active {
        display: none;
    }


.header-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}

.payout-popover-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px 41px;
}

.icon-search {
  background-image: url(../img/svg/search.svg);
}

.icon-wallet {
    background-image: url(../img/svg/Wallet.svg);
}

.icon-paper-clip {
    margin-left: 3px;
    opacity: 0.5;
    background-image: url(../img/svg/paper-clip.svg);
}

.icon-paper-clip.clipped {
    opacity: 1;
}

.icon-paper-clip:hover {
    opacity: 1;
}

.icon-saved-students {
    background-image: url(../img/savedStudents.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-calendar {
  background-image: url(../img/svg/Calendar.svg);
}

.icon-mail {
  background-image: url(../img/svg/MessageOrange.svg);
}

.icon-accept-condition {
    background-image: url(../img/svg/Cloud.svg);
}

.icon-mail-dark {
  background-image: url(../img/svg/MessageBlack.svg);
}

.icon-news {
  background-image: url(../img/svg/news.svg);
}

.icon-logout {
  background-image: url(../img/svg/Logout.svg);
}

.icon-time {
  background-image: url(../img/svg/Time\ Square\ 2.svg);
}

.icon-paper {
  background-image: url(../img/svg/Paper.svg);
}

.icon-delete {
  background-image: url(../img/svg/Delete.svg);
  opacity: 0.5;
}
.header-menu-group {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 18px;
  box-sizing: border-box;
  border-right: 1px solid rgba(224, 224, 224, .5);
}

    .header-menu-group.search-active {
        display: none;
    }

    .header-menu-group > a {
        position: relative;
        margin: 0 24px;
    }

.header-menu-group>button {
  position: relative;
  margin: 0 24px;
}

.header-count {
    position: absolute;
    right: -8px;
    bottom: 0px;
    width: 14px;
    height: 14px;
    padding-top: 1px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-red);
    border-radius: 50%;
    /*border: 1px solid #FFFFFF;*/
    font-weight: 600;
    font-size: 9px;
    line-height: 9px;
    color: #FFFFFF;
}

.header-account {
  margin-left: 44px;
  margin-right: 44px;
  display: flex;
  align-items: center;
  width: 244px;
}

    .header-account .logo-image {
        display: inline-block;
        margin-right: 1px;
        width: 42px;
        height: 42px;
        background-image: url("../img/login.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        vertical-align: middle;
    }

.account-type {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}
    .account-type a {
        text-decoration: none;
    }

    a .account-type {
        text-decoration: none;
    }

    .basic {
        background-color: rgba(55, 140, 189, .3);
    }
        .basic a {
            color: #0C5884;
        }

        a .basic {
            color: #0C5884;
        }

    .pro{
        background-color: rgba(238, 117, 38, 1);
    }
        .pro a {
            color: #FFFFFF;
        }

        a .pro {
            color: #FFFFFF;
        }

.account-title {
  margin-left: 16px;
}

.account-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: black;
}

.account-status {
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-dark);
}

    .account-status button{
        text-align: left;
    }

    .header-logout {
        margin-left: 20px;
    }

.account-status .on-vocation {
    color: var(--color-orange);
}

/* sidebar */

body.menu-opened {
    position: fixed;
    overflow-y: hidden;
}

    .menu-opened::before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(34, 34, 34, 0.5);
        z-index: 1001;
    }

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 284px;
    min-height: 100vh;
    box-sizing: border-box;
    color: #2B2B2B;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    /*overflow-y: hidden;*/
    overflow-y: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    z-index: 1002;
}

    .sidebar::-webkit-scrollbar {
        width: 0;
    }

.sidebar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98px;
  box-shadow: 1px 1px 1px 0px #E0E0E0;
  box-sizing: border-box;
}

.logo-burger {
    /*flex-grow: 1;*/
    display: none;
}

.sidebar-menu {
  padding: 0 10px 26px 20px;
  overflow-y: auto;
}

.sidebar-menu>li {
  margin-top: 26px;
}

.sidebar-menu>li>span {
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #2B2B2B;
}

.submenu {
  margin-top: 20px;
  /* padding-top: 28px; */
}

    .submenu > li > a,
    .submenu > li > button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        margin-top: 10px;
        padding-left: 14px;
        padding-right: 6px;
        box-sizing: border-box;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #2B2B2B;
        border-radius: 8px;
    }

.menu-active {
  background: rgba(238, 117, 38, .15);
}

.menu-icon {
  margin-right: 14px;
  width: 16px;
  height: 16px;
  background-image: url(../img/svg/Star.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}

.icon-1 {
  background-image: url(../img/svg/Star.svg);
}

.icon-2 {
  background-image: url(../img/svg/Work.svg);
}

.icon-3 {
  background-image: url(../img/svg/More\ Square.svg);
}

.icon-4 {
  background-image: url(../img/svg/Time\ Square.svg);
}

.icon-5 {
  background-image: url(../img/svg/Time\ Circle.svg);
}

.icon-6 {
  background-image: url(../img/svg/Play.svg);
}

.icon-7 {
    background-image: url(../img/svg/Document.svg);
}

.icon-8 {
    background-image: url(../img/svg/Edit\ Square.svg);
}

.icon-9 {
  background-image: url(../img/svg/Bookmark.svg);
}

.icon-10 {
  background-image: url(../img/svg/Folder.svg);
}

.icon-11 {
  background-image: url(../img/svg/Document2.svg);
}

.icon-12 {
    background-image: url(../img/svg/Dispute.svg);
}

.menu-count {
  margin-left: auto;
  padding: 4px 8px 3px 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  background-color: rgba(237, 118, 38, .1);
  border-radius: 4px;
  color: #2B2B2B;
}

.menu-count-attention {
    color: var(--color-red);
}

.menu-count-unseen {
    font-weight: 800;
}
/* main */

.main-wrapper {
    /*display: flex;
    flex-direction: column;
    padding-left: 284px;
    padding-top: 99px;
    min-height: calc(100vh - 99px);
    min-height: 100vh;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(43, 43, 43, 0.05), rgba(43, 43, 43, 0.05)), linear-gradient(0deg, #FFFFFF, #FFFFFF), #E5E9EB;
    font-size: 14px;*/
}

.main-wrapper>h1 {
  margin-top: 36px;
  margin-left: 30px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #404040;
}

.main-wrapper>h2 {
  margin-top: 43px;
  margin-bottom: -28px;
  /* margin-bottom: 8px; */
  margin-left: 30px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #404040;
}

.main-wrapper p {
  font-size: 14px;
  line-height: 24px;
}

.main-wrapper-404 {
    box-sizing: border-box;
    min-height: 100vh;
    background: linear-gradient( 0deg, rgba(43, 43, 43, 0.05), rgba(43, 43, 43, 0.05)), linear-gradient( 0deg, #FFFFFF, #FFFFFF), #E5E9EB;
}

.posRelative {
    position: relative;
}

.posAbsolute {
    position: absolute
}

.content {
  /*flex-grow: 1;
  max-height: 100%;
  padding-top: 24px;
  padding-left: 30px;
  padding-right: 64px;
  padding-bottom: 60px;
  background-color: #FFFFFF;*/
}

.content h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #404040;
}

    .content h3 {
        margin-top: 7px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
    }

.content>h4, .content>div>h4 {
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.content>p {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
}

.content .hr, .modal .hr {
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
  width: 100%;
  height: 1px;
  background: #E0E0E0;
}

.content-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    width: 100%;
    max-width: 1200px;
}

.content-description-danger, p.content-description-danger {
  margin-top: 16px;
  position: relative;
  padding-left: 30px;
}

.content-description-danger::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(../img/svg/Danger.svg);
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.content-description .table {
  margin: 0 -14px;
}

.content-description .table-row .table-col {
  padding: 4px 14px;
}
/*
.filters-wrapper {
    margin-top: 24px;
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

.filters-wrapper .filter-item:first-of-type {
  margin-right: auto;
}

.filter-item {
  margin-left: 10px;
  margin-right: 10px;
}

.filter-item>select, .filter-item>input[type="text"] {
  width: 100%;
  max-width: 100%;
}

.filter-select {
    flex-grow: 1;
    max-width: 220px;
    margin-left: 10px;
    margin-right: 10px;
}

.filter-item.radio-group>.radio-item {
  margin-right: 32px;
}
*/

.filter-item.by-day {
    margin-top: 17px;
}

@media (min-width: 1680px) {
    .filter-item.empty {
        width: 720px;
    }
}

.filter-item.by-sciences {
    margin-right: 10px;
    margin-top: 9px;
}
/* table */

.content-table {
  margin-top: 28px;
  width: 100%;
  max-width: 1200px;
  font-size: 13px;
  line-height: 14px;
  color: var(--color-dark);
}

.content-table thead {
  /* background-color: rgba(238, 117, 38, .08); */
  background-color: #F9F5F1;
  /* doesn't work */
  /* border-radius: 8px; */
}

.content-table thead tr:first-child td:first-child {
  border-top-left-radius: 8px;
}

.content-table thead tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

.content-table thead tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.content-table thead tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
/*
    .content-table thead tr td:first-child{
        text-align: center;
    }*/

.content-table thead tr td {
    padding: 14px 10px 12px 10px;
    box-sizing: border-box;
    min-height: 40px;
    font-weight: normal;
    opacity: 0.7;
}

.table-sort {
    vertical-align: middle;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
}

    .table-sort .table-sort-up, .table-sort .table-sort-down {
        display: inline-block;
        width: 7px;
        height: 7px;
        background-image: url(../img/svg/Arrow-Down2.svg);
        background-size: 7px 7px;
        background-repeat: no-repeat;
    }

    .table-sort .table-sort-up {
        margin-bottom: 3px;
        transform: rotate(180deg);
    }

    .table-sort .table-sort-up-active, .table-sort .table-sort-down-active {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: url(../img/svg/Arrow-Down2.svg);
        background-size: 10px 10px;
        background-repeat: no-repeat;
    }

    .table-sort .table-sort-up-active {
        transform: rotate(180deg);
    }

.content-table tbody {
  /* TODO: to fix */
  /* border-top: 16px solid transparent; */
}

.content-table tbody tr {
  border-bottom: 0.5px solid #ECECEC;
}

    .content-table tbody tr.in-work {
        background-color: #fdf5ed;
    }

    .content-table tbody tr.rework {
        background-color: #ebfbff;
    }

.content-table-noborder tbody tr {
  border-bottom: none;
}
.content-table tbody tr td {
  min-height: 40px;
  padding: 16px 10px;
  box-sizing: border-box;
  text-align: left;
  vertical-align: top;
  font-weight: normal;
  color: var(--color-gray);
}

    .content-table tbody tr td:not(:last-child) {
        border-right: 0.5px solid #ECECEC;
    }


.content-table tbody tr td .order-status-code {
    display: flex;
}

.content-table .separate-row td {
    text-align: center;
}
/*
.content-table tbody tr:not(.separate-row) td:first-child {
    width: 91px;
    text-align: end;
}*/

    .content-table tbody tr:not(.separate-row) td:first-child span {
        cursor: pointer;
    }
    
.content-table.content-table-small thead tr td {
    padding: 14px 20px 12px 20px;
}

.content-table.content-table-small tbody tr td {
  padding: 8px 20px;
}

.content-table b {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: var(--color-dark);
}

.content-table .status-column {
    min-width: 100px;
    max-width: 115px;
}

.content-table .theme-column {
    max-width: 1000px;
}

.content-table .type-column {
    min-width: 160px;
    max-width: 180px;
}

.content-table .deadline-column {
    min-width: 105px;
    max-width: 120px;
}

.content-table .price-column {
    min-width: 71px;
    max-width: 90px;
}

.content-table-bold {
    font-size: 15px;
    line-height: 17px;
    font-weight: bold;
    color: var(--color-dark);
}

.order-table-button{
    text-align: left;
    width: 100%;
}

.content-table-normal {
    font-size: 15px;
    line-height: 17px;
    font-weight: normal;
    color: var(--color-dark);
}

.order-status {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: transparent;
}

/*
.order-status::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--color-gray);
  border-radius: 50%;
}
*/

.status-orange::before {
    background: var(--color-orange);
    cursor: pointer
}

.status-blue::before {
    background: var(--color-blue);
    cursor: pointer
}

.status-orange-old::before {
    background: #ffffff;
    border-color: var(--color-orange);
    border-style: solid;
    border-width: thin;
    width: 6px;
    height: 6px;
}

.status-blue-old::before {
    background: #ffffff;
    border-color: var(--color-blue);
    border-style: solid;
    border-width: thin;
    width: 6px;
    height: 6px;
}

.status-edit::before {
  width: 10px;
  height: 10px;
  border-radius: unset;
  background: url(../img/svg/EditSquareBlue.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
}

.order-confirmed {
  display: inline-flex;
  align-items: center;
  min-height: 18px;
  padding-left: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #27AE60;
  background-image: url(../img/svg/Tick\ Square\ green.svg);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: left center;
}
.content-table .separate-row {
  border-bottom: none;
}

.content-table .separate-title {
  display: inline-block;
  width: 100%;
  max-width: 380px;
  padding: 6px 24px;
  box-sizing: border-box;
  /*background-color: rgba(238, 117, 38, 0.08);*/
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #404040;
}

.mobile-only .separate-title {
    display: inline-block;
    width: 100%;
    max-width: none;
    /*background-color: rgba(238, 117, 38, 0.08);*/
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #404040;
    text-align: center;
    margin-left: -24px;
    box-sizing: content-box;
}

.order-item {
  margin-left: -24px;
  margin-right: -24px;
}

.order-item-title {
  min-height: 39px;
  padding: 12px;
  margin: 0px 24px;
  box-sizing: border-box;
  text-align: center;
  background-color: #F9F5F1;
}

    .order-item-title.in-work {
        background-color: #fdf5ed !important;
    }

    .order-item-title.rework {
        background-color: #f5fdff !important;
    }

.order-item-title-params {
    display: flex;
    justify-content: space-between;
    min-height: 51px;
    padding: 12px;
    margin: 0px 24px;
    box-sizing: border-box;
    text-align: center;
    background-color: #F9F5F1;
}

    .order-item-title-params.in-work {
        background-color: #fdf5ed !important;
    }

    .order-item-title-params.rework {
        background-color: #f5fdff !important;
    }

    .order-item-title-params>div{
        margin-top: auto;
        margin-bottom: auto;
    }

        .order-item-title-params>div:first-of-type {
            padding-left: 12px;
        }

        .order-item-title-params>div:last-of-type {
            padding-right: 12px;
        }

.order-item-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #F9F5F1;
}

.order-item>.order-item-row:last-of-type {
  padding-bottom: 24px;
  border-bottom: none;
}

.order-item-row>div {
  box-sizing: border-box;
}

.order-item-row>div:first-of-type {
  max-width: 40%;
  padding-left: 35px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 22px;
  opacity: 0.8;
}

.order-item-row>div:last-of-type {
  max-width: 60%;
  padding-right: 35px;
  padding-left: 8px;
  font-size: 12px;
  line-height: 22px;
  text-align: right;
}

.table-row-total td {
  padding-top: 16px !important;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  border-bottom: 1px solid #F2F2F2;
}

/* 404 */

.page-404-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  /*min-height: 100vh;*/
}

.page-404-wrapper h1 {
  font-weight: bold;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  color: var(--color-dark);
}

.page-404-wrapper h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--color-dark);
  margin-top: 8px;
}

.page-404-wrapper .button {
  width: 254px;
  height: 49px;
  margin-top: 32px;
  padding: 16px;
  box-sizing: border-box;
}

/* author category, account types */
.modal-category {
}

.modal-category p, .modal-account-form p {
  margin-top: 24px;
  font-size: 15px;
  line-height: 24px;
}

.modal-category ol {
  margin-top: 8px;
  margin-bottom: 24px;
}

.modal-category ol>li {
  font-size: 15px;
  line-height: 24px;
}

.tabs {
  display: flex;
  margin-left: -20px;
  margin-top: -20px;
  margin-top: 24px;
  padding-top: 32px;
  border-top: 1px solid #C4C4C4;
}

.tab-item {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--color-dark);
}

.tab-item.tab-active {
  color: var(--color-orange);
  border-bottom: 1px solid var(--color-orange);
}

.tab-content {
  margin-top: 24px;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.tab-pane-active {
  display: block;
}

.modal-order-status, p.modal-order-status {
  margin-top: 10px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.modal-info {
  width: 100%;
  max-width: 368px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 24px 24px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  background: rgba(238, 117, 38, 0.08);
}

.modal-info-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.modal-info p {
  margin-top: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.money-rules {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: -40px;
}

.money-rule-item {
  width: 25%;
  max-width: 210px;
  margin-bottom: 40px;
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: border-box;
  text-align: center;
}

.money-rule-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.money-rule-img img {
  width: 60px;
  height: 60px;
}

.money-rule-item p {
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-gray-2);
}

.money-rule-item .money-rule-note {
  margin-top: 8px;
  font-size: 10px;
  line-height: 12px;
}

.modal-category-button {
  margin-top: 72px;
}

/* account form modal */

.account-form-info p {
  margin-top: 12px;
}

.account-steps-wrapper {
  margin-top: 24px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 28px 48px 16px 40px;
  box-sizing: border-box;
  background-color: rgba(238, 117, 38, 0.08);
}

.account-steps {
  display: flex;
  justify-content: space-between;
}

.account-step {
  width: 20%;
  padding: 0 2px;
  box-sizing: border-box;
  opacity: 0.3;
  color: var(--color-dark);
}

.account-step-line {
  height: 4px;
  border-radius: 4px;
  background-color: var(--color-dark);
}

.account-step-name {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.account-step-name::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid var(--color-dark);
}

.account-step-active {
  opacity: 1;
}

.account-step-active .account-step-line {
  background-color: var(--color-orange);
}

.account-step-active .account-step-name::before {
  border: 5px solid var(--color-orange);
}

.account-form-step {
  display: none;
  margin-top: 20px;
}

.account-form-step.form-step-active {
  display: block;
}

.account-form-step h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.account-form-step p {
  margin-top: 10px;
  font-size: 14px;
  line-height: 24px;
}

.account-form-items {
  margin-top: 20px;
}

.account-form-items .radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.account-form-items .radio-item:not(:first-child) {
  margin-top: 24px;
}

.account-form-items .radio-item-name {
  font-size: 15px;
}

.account-form-items .form-item {
  max-width: 420px;
  margin-bottom: 15px;
}

.account-form-items .account-from-group {
  display: flex;
}

.account-form-items .account-from-group input {
  margin-right: 12px;
}

.account-form-buttons {
    margin-top: 32px;
    display: inline-flex;
}

.account-form-buttons .button {
  margin-right: 16px;
}

.account-res-table {
  width: 100%;
  margin-bottom: 24px;
}

.account-res-table tr {}

.account-res-table tr:nth-child(odd) td {
  background-color: #F9F5F1;
}

.account-res-table tr td {
  padding: 6px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.account-res-table tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.account-res-table tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.account-valid-marker {
    margin-left: 16px;
}

/* news */

.back {
  text-align:left;
  display: inline-block;
  margin-left: 30px;
  margin-top: 26px;
  padding-left: 30px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #404040;
  background-image: url(../img/svg/back.svg);
  background-size: 18px 11px;
  background-position: 0 2px;
  background-repeat: no-repeat;
}

.news-item {
  padding: 24px 0;
  border-bottom: 0.5px solid #ECECEC;
}

.news-item.news-top {
  margin: -24px -64px 24px -40px;
  padding: 24px 40px;
  background: rgba(55, 140, 189, 0.03);
  border-bottom: none;
}

.news-header {
  display: flex;
}

.news-img {
  min-width: 40px;
  height: 40px;
  margin-right: 24px;
  border-radius: 4px;
  overflow: hidden;
}

.news-img {
  max-width: 100%;
  max-height: 100%;
}

.news-title {
  font-size: 12px;
}

.news-title h3 {
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-gray);
}

.news-body {
    margin-top: 22px;
    word-wrap: break-word;
}

.news-body p {
  font-size: 12px;
  line-height: 18px;
  color: var(--color-gray);
}

/* order-page */

.order-descr-table {
    margin-left: -12px;
    margin-right: -12px;
    /*margin-bottom: -10px;*/
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 24px;
}

.order-descr-table tr td {
  padding: 10px 12px;
}

.order-param, p.order-param {
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
}

.order-descr {
  display: flex;
}

.order-descr-item {
  position: relative;
  max-width: 220px;
  margin-left: 24px;
  margin-right: 24px;
  padding-left: 34px;
}

.order-descr-item .header-icon {
  position: absolute;
  top: 4px;
  left: 0;
}

.order-descr-item .order-param {
  margin-bottom: 4px;
}
    .order-descr-item .text-underline{
        cursor: default;
    }

.order-param .icon-question {
    margin-left: 7px;
    vertical-align: text-bottom;
    cursor: pointer;
}

.icon-question {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: text-bottom;
  background-image: url(../img/svg/question.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 14px;
}

.icon-warning {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: text-bottom;
    background-image: url(../img/svg/Info\ Circle.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    cursor: pointer;
    margin-left: 10px;
}

/* TODO: создать общий класс для icon-info и icon-question */

.icon-info {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: text-bottom;
  background-image: url(../img/svg/InfoCircle.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  cursor: pointer;
}

.order-files {
  position: relative;
  padding-left: 30px;
}

.order-files .header-icon {
  position: absolute;
  top: 0;
  left: 0;
}

.order-files .icon-question {
  margin-left: 6px;
}

.order-files ul li {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding-top: 10px;
  padding-bottom: 10px;
   background-image: url(../img/svg/Paper\ Download.svg);
  background-size: 16px 16px;
  background-position: left center;
  background-repeat: no-repeat; */
}

    .order-files ul li a,
    .order-files ul li button {
        position: relative;
        padding-left: 12px;
        text-align: left;
    }
    /*
    .order-files ul li a::before,
    .order-files ul li button::before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 8px);
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url(../img/svg/Paper\ Download.svg);
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
    }*/

.order-files ul li a {
  color: #000000;
}

.order-files-editable {
  padding-left: 0;
}

.order-files-editable h3 {
  padding-left: 30px;
}

/*separation-order-files*/

.separation-order-files {
    position: relative;
    padding-left: 30px;
}

.separation-order-files .header-icon {
    position: absolute;
    top: 0;
    left: 0;
}

.separation-order-files .icon-question {
    margin-left: 6px;
}

.separation-order-files ul li {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

    .separation-order-files ul li span {
        position: relative;
        padding-right: 10px;
        text-align: left;
        font-size: 12px;
    }

.separation-order-files ul li a,
.separation-order-files ul li button{
    position: relative;
    padding-left: 10px;
    text-align: left;
}

.separation-order-files ul li a {
    color: #000000;
}

    .separation-order-files ul li ul li {
        margin: 0;
    }

    .separation-order-files ul li ul li:not(:first-child) {
        margin-top: 10px;
    }

.download-file-icon {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 8px);
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(../img/svg/Download.svg);
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.download-file-message-icon {
    content: '';
    position: relative;
    left: 0;
    top: 2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(../img/svg/Download.svg);
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.view-icon {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 3px;
    background-image: url(../img/svg/Eye.svg);
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
}

/*order-files-separation*/

.file-date {
  margin-right: 14px;
  font-size: 12px;
  color: #000000;
}

.button-edit {
  display: inline-flex;
  margin-left: 12px;
}

.button-edit::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(../img/svg/Edit.svg);
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
}

.order-file-edited {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
}

.order-file-edited input {
  width: 100%;
  max-width: 350px;
  height: 28px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 4px 12px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.order-file-edited .button {
  margin-left: 8px;
  margin-right: 8px;
  height: 28px;
  min-height: 28px;
  padding: 5px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.order-buttons {
  margin-left: -8px;
  margin-right: -8px;
}

.order-buttons .button {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.modal-accept-order, .modal-order-question, .modal-order-question, .modal-order-checkConditions, .modal-order-thanks, .modal-on-vocation, .modal-check-residency, .modal-thanks {
    max-width: 630px;
}

.modal-accept-order .button-group {
  margin-top: 32px;
}

.modal-thanks .button-group {
    margin-top: 32px;
}

.modal-order-thanks .button-set-discipline {
    width: 100%;
}

.modal-check-residency .button-group {
    margin-top: 32px;
}

.modal-accept-order .button-group .button {
  max-width: 160px;
  width: 100%;
}

.modal-check-residency .button-group .button {
    max-width: 160px;
    width: 100%;
}

.modal-your-conditions {
  max-width: 670px;
}

.modal-your-conditions .form-item textarea, .modal-order-question .form-item textarea, .modal-order-cancel .form-item textarea {
  height: 84px;
}

.fast-answers {
  margin-top: 8px;
}

.fast-answers li {
  margin-bottom: 4px;
}

    .fast-answers li a, .fast-answers li button {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        opacity: 0.8;
        text-align: start;
    }

.order-chat {
  max-width: 618px;
  margin-top: 24px;
}

.chat {
  max-width: 625px;
  padding: 0 0 32px 27px;
  box-sizing: border-box;
  background: rgba(55, 140, 189, 0.02);
  border: 1px solid rgba(55, 140, 189, 0.2);
  border-radius: 4px;
}

.chat-body-wrapper {
  height: 512px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;
}

    .chat-body-wrapper::-webkit-scrollbar {
        width: 5px;
    }

    .chat-body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: RGBA(55, 140, 189, 0.15);
        cursor: pointer;
    }

        .chat-body-wrapper::-webkit-scrollbar-thumb:active {
            border-radius: 10px;
            background-color: RGBA(55, 140, 189, 0.25);
        }

.chat-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
}

.chat-date {
  margin: 4px auto;
  padding: 5px 24px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  color: var(--color-gray-2);
  border: 1px solid rgba(34, 34, 34, .2);
  box-sizing: border-box;
  border-radius: 4px;
}

.chat-message {
    width: 100%;
    max-width: 80%;
    margin-top: 20px;
    white-space: pre-line;
}

.chat-message.message-right {
  align-self: flex-end;
}

.chat-message.message-left {
  align-self: flex-start;
}

.chat-message>.chat-message-text {
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-dark);
}

.chat-message.message-right>.chat-message-text {
  background: linear-gradient(0deg, #FFD3B7, #FFD3B7), #FFFFFF;
  border-radius: 6px 6px 0px 6px;
}

.chat-message.message-left>.chat-message-text {
  background: linear-gradient(0deg, rgba(69, 163, 217, 0.1), rgba(69, 163, 217, 0.1)), #FFFFFF;
  border-radius: 6px 6px 6px 0px;
}

.chat-message-text > a,
.chat-message-text > button {
    font-weight: 900;
    color: black;
    text-align: left;
}

    .chat-message-text > a:hover,
    .chat-message-text > button:hover {
        color: var(--color-orange);
    }

.chat-message-time {
    opacity: 0.3;
}

.chat-message-info {
    margin-top: 8px;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-dark);
}

.chat-message.message-right > .chat-message-info {
    text-align: right;
}

.chat-message.message-left > .chat-message-info {
    text-align: left;
}

.chat-message-info .delivered::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 11px;
    background-image: url(../img/message-delivered.png);
    background-size: 14px 14px;
    background-repeat: no-repeat;
}

.chat-message-info .readed::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 11px;
    background-image: url(../img/message-readed.png);
    background-size: 14px 14px;
    background-repeat: no-repeat;
}

.chat-write {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 32px;
    padding-right: 24px;
}

/*.chat-write-row{
    width: 100%;
    display: flex;
}*/

.chat-write .button {
  margin: auto;
}

/*.chat-attach {
  display: inline-flex;
  margin-right: 16px;
}

.chat-attach::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(../img/svg/attach.svg);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  cursor: pointer;
}*/

.chat-input {
    flex-grow: 1;
    margin-bottom: 20px;
    padding: 0 0 10px 0;
    font-weight: normal;
    font-size: 14px;
    max-height: 48px;
    color: rgba(34, 34, 34);
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
    overflow-y: auto;
    padding-right: 10px;
    resize: none;
}

.chat-input::-webkit-input-placeholder, .chat-input:-ms-input-placeholder, .chat-input::-moz-placeholder, .chat-input:-moz-placeholder {
  color: rgba(34, 34, 34, 0.3);
  opacity: 1;
}

.chat-drop-area {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-right: 24px;
    padding: 30px 0;
    border: dashed 3px rgba(69, 163, 217, 0.3);
    border-radius: 3px;
    align-items: center;
    justify-content: center;
}

    .chat-drop-area.active {
        background: rgba(69, 163, 217, 0.1);
    }
    /*.chat-drop-area:not(.active) {
        background: rgba(69, 163, 217, 0.1);
    }*/

/* final version modal */
.modal-final-version {
    color: var(--color-gray-2);
}

.modal-final-version h2 {
  text-align: left;
}

.modal-final-version h3 {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.modal-final-version p {
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
}

.plagiat-info, .info-box {
    position: relative;
    margin-top: 12px;
    margin-bottom: 24px;
    padding: 16px 20px 16px 20px;
    background: rgba(238, 117, 38, 0.08);
    border-radius: 8px;
    font-size: 12px;
    line-height: 22px;
    color: var(--color-dark);
}

.plagiat-info {
    margin-top: 12px;
    margin-bottom: 24px;
    padding: 12px 26px 12px 52px;
}

.plagiat-info::before {
  content: '';
  position: absolute;
  top: 16px;
  left: 18px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(../img/svg/Info\ Circle.svg);
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.final-version-files h3 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-gray-2);
}

.final-version-row {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.final-version-row>.final-version-col:first-child {
  margin-right: 52px;
  min-width: 270px;
}

.final-version-row>.final-version-col:last-child {
  max-width: 500px;
}

.files-category {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.select-file-group {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.select-file-group .button {
  margin-right: 16px;
}

.selected-file {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-gray-2);
    max-width: 100px;
}

.selected-file-remove {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 8px;
  background-image: url(../img/svg/file-close.svg);
  background-size: 8px 8px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.final-version-col p {
  margin-top: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.modal-final-version .button-group {
  margin-top: 48px;
  margin-left: -8px;
  margin-right: -8px;
}

.help-articles li {
  padding: 16px 0;
  border-bottom: 0.5px solid #ECECEC;
}

.article-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-orange);
}

.article-top-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BDBDBD;
}

/* faq */
.faq-item {
    padding: 24px 0;
    border-bottom: 0.5px solid #ECECEC;
    color: #404040;
}

.faq-title {
  position: relative;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  padding-right: 24px;
  cursor: pointer;
}

.faq-title::before {
  content: '';
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  display: inline-block;
  width: 16px;
  height: 14px;
  background-image: url(../img/svg/Arrow\ -\ Down\ 2.svg);
  background-size: 16px 14px;
  background-repeat: no-repeat;
  transition: transform .3s ease-out;
}

.faq-active .faq-title::before {
  transform: rotate(180deg);
}

.faq-body {
  display: none;
  margin-top: 4px;
}

.faq-active .faq-body {
  display: block;
}

.faq-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
}

.faq-links {
  margin-top: 8px;
}

.faq-links a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

/* offer contract */
.offer-contract-content{
    text-align: justify;
}

    .offer-contract-content p{
        max-width: 1000px;
    }
    
    .offer-contract-content h1 {
        margin-top: 20px;
        text-align: center;
        max-width: 1000px;
    }

    .offer-contract-content a {
        text-decoration: none;
        color: black;
    }

/*logout-article-content*/

.logout-article-content {
    text-align: justify;
}
    .logout-article-content p {
        max-width: 900px;
        margin: 0 auto;
    }

    .logout-article-content h1 {
        text-align: center;
        margin-bottom: 20px;
    }

.logout-article-pda-content {
    text-align: justify;
}

    .logout-article-pda-content p {
        max-width: 900px;
        margin: 0 auto;
        text-indent: 30px;
    }
    
    .logout-article-pda-content h1 {
        text-align: center;
        margin-bottom: 20px;
    }

/* order subjects */
.subject-group {
    margin-top: 40px;
}

    .subject-group h3 {
        margin: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

.form-row-personal-info {
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;
    margin-right: -24px;
}

    .form-row-personal-info .form-item {
        width: 100%;
        max-width: 50%;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
    }

    .form-row-personal-info .subject-item {
        margin-bottom: 0;
        margin-top: 24px;
    }

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;
    margin-right: -20px;
}

    .form-row .form-item {
        width: 100%;
        max-width: 33.333%;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
    }

    .form-row .subject-item {
        margin-bottom: 0;
        margin-top: 24px;
    }

.form-row-checkbox {
    display: inline-flex;
    margin-left: 15px;
}

    .form-row-checkbox .form-item-checkbox {
        margin-right: 40px;
    }

.subject-select-wrapper {
    position: relative;
}

.subject-item select, .subject-select {
  width: 100%;
  height: 40px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #828282;
}

.subject-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 16px 10px 16px;
}

    .subject-select::after {
        content: '';
        align-self: center;
        width: 10px;
        height: 8px;
        background-image: url(../img/svg/Arrow-Down2.svg);
        background-size: 10px 8px;
        opacity: 0.8;
        transition: transform .3s ease-out;
    }

.subject-select-active.subject-select::after {
    transform: rotate(180deg);
}

.subject-filter-select {
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px 10px 16px;
}

    .subject-filter-select::after {
        content: '';
        align-self: center;
        width: 10px;
        height: 8px;
        background-image: url(../img/svg/Arrow-Down2.svg);
        background-size: 10px 8px;
        opacity: 0.8;
        transition: transform .3s ease-out;
    }

.subject-select-unselected {
    border: 1px solid #BDBDBD;
}

.subject-select-selected {
    border: 2px solid #EE7526;
}

.subject-select-active.subject-filter-select::after {
    transform: rotate(180deg);
}

.subject-popover {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    padding: 16px 16px 0;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 4px 4px;
    z-index: 10;
}

    .subject-popover > ul > li {
        margin-bottom: 16px;
    }

    .subject-popover > ul > li {
        margin-bottom: 9px;
    }

.subject-filter-popover {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 4px 4px;
    z-index: 10;
}

    .subject-filter-popover > ul > li {
        margin-bottom: 6px;
    }

.subject-accept {
  /* margin-top: 16px; */
  width: 100%;
  min-height: 32px;
  padding: 6px 24px 6px 24px;
}

.specialities-button {
    margin-top: 16px;
    width: 200px;
    /*min-height: 32px;*/
    padding: 6px 24px 6px 24px;
}

/* profile */
.content-tabs-wrapper {
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
}

.content-tabs {
  display: flex;
  margin-left: 16px;
  margin-top: 16px;
  margin-top: 12px;
}

.content-tab-item {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #404040;
}

.content-tab-item.tab-active {
  color: var(--color-orange);
  border-bottom: 2px solid var(--color-orange);
}

.profile-top-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BDBDBD;
}

.profile-order-status {
    font-weight: 700;
    margin-top: 20px;
}

.form-personal-info {
    margin-top: 32px;
    max-width: 918px;
}

.form-change-password {
    margin-top: 32px;
    max-width: 400px;
}

.form-payment-info {
    margin-top: 16px;
    max-width: 600px;
}

    .form-personal-info .form-row-personal-info .form-item, .form-payment-info .form-item, .form-change-password .form-item {
        margin-bottom: 32px;
    }

.form-payment-is-resident {
    margin-top: 16px;
    max-width: 600px;
    min-height: 240px;
}

.form-payment-is-resident h3 {
    margin-bottom: 16px;
}

.form-payment-is-resident button{
    min-width: 150px;
}


.form-personal-info .form-hr {
    margin-bottom: 24px;
}

.form-personal-info h2 {
    margin-bottom: 32px;
}

.profile-education {
    display: block;
    min-height: 193px;
    max-width: 606px;
}

.modal-edit-profile {
    max-width: 370px;
}

    .modal-edit-profile textarea {
        min-height: 84px;
    }

.profile-payment-row {
    display: flex;
    align-items: flex-start;
    margin-top: 64px;
}

.profile-category-info {
    max-width: 606px;
}

.profile-account-info {
    max-width: 368px;
    margin-left: 64px;
}

.info-box {
    padding: 24px;
    box-sizing: border-box;
    background: rgba(238, 117, 38, 0.08);
    border-radius: 8px;
    color: #404040;
    max-width: 450px;
}

    .info-box h4 {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }

        .info-box h4 b {
            font-weight: 700;
        }

    .info-box p {
        margin-top: 24px;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }

/* bonus */

.bonus-top h3 {
  margin: 0;
  margin-bottom: 16px;
  font-weight: 600;
				 
				   
	
}

.bonus-top p {	 
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 32px;
  color: #222222;
}

.bonus-count {
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  opacity: 0.5;
}

/* image modal */

.modal-image-block {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
}

.modal-image {
    position: relative;
    margin: auto;
    display: block;
    max-width: 80%;
    max-height: 70%;
}

/* chat modal */
.modal-chat {
    padding: 0;
    max-width: 1155px;
    max-height: 926px;
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.modal-chat-row {
  display: flex;
								  
}

.chat-middle-col {
  /*border-left: 1px solid rgba(51, 51, 51, 0.1);*/
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  flex-grow: 1;
  position: relative;
  max-width: 627px;
}

.chat-right-col {
    width: 348px;
    padding: 20px 18px 20px 16px;
    box-sizing: border-box;
    max-height: 926px;
    overflow-x: hidden;
    overflow-y: auto;
}

    .chat-right-col::-webkit-scrollbar {
        width: 5px;
    }

    .chat-right-col::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(238, 117, 38, 0.25);
        cursor: pointer;
    }

.chat-orders-wrapper {
    width: 180px;
    border-right: 1px solid rgba(51, 51, 51, 0.1);
    max-height: 926px;
    overflow: hidden;
    min-width: 144px;
}

.main-chat-wrapper {
    background: #FFFFFF;
}

.main-chat-wrapper-nav {
    /*position: relative;
    padding-top: 30px;
    padding-bottom: 40px;*/
    position: absolute;
    z-index: 100;
}

    .main-chat-wrapper-nav h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }

    .main-chat-wrapper-nav .back {
        position: absolute;
        left: 16px;
        top: 22px;
        margin: 0;
    }

.main-chat-wrapper .chat-orders-wrapper {
    width: 100%;
}

.main-chat-wrapper .chat-support {
    border-top: 1px solid rgba(55, 140, 189, 0.15);
}

.chat-support {
    position: relative;
    display: flex;
    padding: 20px 10px 20px 30px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    border-bottom: 1px solid rgba(55, 140, 189, 0.15);
    width: 100%;
    display: none;
}

    .chat-support.chat-menu-status {
        padding: 20px 10px 20px 36px;
    }

.chat-support.chat-menu-status::before {
  top: 24px;
  left: 20px;
}

.chat-support .icon-question {
  margin-left: 6px;
}

.chat-menu-wrapper {
    overflow-y: auto;
    max-height: 924px;
}

    .chat-menu-wrapper::-webkit-scrollbar {
        width: 5px;
    }

    .chat-menu-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(238, 117, 38, 0.25);
        cursor: pointer;
    }

.chat-menu-title {
  display: flex;
  padding: 26px 10px 16px 15px;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #2B2B2B;
}

.chat-menu-count {
    margin-left: auto;
    white-space: nowrap;
    align-self: center;
}

.chat-menu-count .icon-info {
  margin-right: 4px;
}

.chat-menu li a, .chat-menu li button {
    display: block;
    padding: 10px;
    text-align: left;
    width: 100%;
}

    .chat-menu li button.chat-menu-active, .chat-menu li a.chat-menu-active, .chat-menu-active {
        background: rgba(238, 117, 38, 0.15);
    }

.chat-menu-status {
  position: relative;
}

.chat-menu-status::before {
  content: '';
  position: absolute;
  left: 85px;
  top: 13px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--color-orange);
}

.chat-menu li a .menu-title, .chat-menu li button .menu-title {
    font-weight: bold;
    font-size: 14px;
    color: #222222;
}

.chat-menu li a .menu-subtitle, .chat-menu li button .menu-subtitle {
    margin-top: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    word-wrap: break-word;
    color: #222222;
    opacity: 0.3;
    height: 14px;
    overflow: hidden;
}

.chat-header {
    position: relative;
    padding: 24px 30px 20px 30px;
    background: rgba(34, 34, 34, 0.02);
    border-bottom: 1px solid rgba(34, 34, 34, 0.01);
    max-height: 230px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 566px;
    overflow-wrap: anywhere;
}

    .chat-header::-webkit-scrollbar {
        width: 5px;
    }

    .chat-header::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(34, 34, 34, 0.25);
        cursor: pointer;
    }

.chat-header-space {
    display: none;
    position: relative;
    width: 100%;
    height: 24px;
    background: rgba(34, 34, 34, 0.02);
}

.chat-header h3 {
  margin: 12px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.chat-header h4, .chat-right-col h4 {
  margin: 0;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.chat-header p {
  margin: 4px 0;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
}

.chat-slider-nav {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    justify-content: center;
}

.chat-slider-nav-item {
    width: 10px;
    height: 10px;
    background: #222222;
    opacity: 0.3;
    margin: 0 13px;
    border-radius: 50%;
}

    .chat-slider-nav-item.active {
        opacity: 1;
    }

.chat-slider-item {
    display: none;
}

    .chat-slider-item.chat-slider-item-showed {
        display: block;
    }

.chat-manager {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.chat-manager-avatar {
  margin-right: 14px;
  width: 32px;
  height: 32px;
}

.chat-manager-avatar img {
  width: 100%;
}

.chat-manager-title {}

.chat-manager-post {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #4F4F4F;
}

.chat-manager-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.chat-order-link {
  margin-top: -4px;
}

.chat-order-link a {
  font-size: 12px;
}

.chat-order-info {
  margin: 0 -24px;
  margin-top: 12px;
}

.chat-order-info .table-col {
  padding: 0 24px;
}

.chat-order-info h4 {
  margin: 0;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.chat-order-info p {
  margin: 0;
  font-size: 14px;
  line-height: 17px;
}

.main-chat-wrapper .chat-middle-col {
    width: 100%;
}

.main-chat-wrapper .chat-manager {
    margin-left: 16px;
}

.main-chat-wrapper .chat-slider-nav {
}

.main-chat-wrapper .chat-slider-item {
    background-color: #FBFBFB;
    padding-top: 40px;
    position: relative;
}

.main-chat-wrapper .chat-header {
    padding-top: 0;
    background: unset;
}

.main-chat-wrapper .chat-slider-nav {
    top: 16px;
}

.chat-right-row {
  padding: 20px 0;
  border-bottom: 1px solid rgba(130, 130, 130, 0.1);
  word-break:break-all;
}

.chat-right-col .chat-right-row:last-of-type {
  border-bottom: none;
}

.chat-right-row.order-files {}

.chat-right-row.order-files li {
  margin-top: 18px;
  margin-bottom: 18px;
}

    .chat-right-row.order-files li a,
    .chat-right-row.order-files li button{
        font-weight: 500;
        font-size: 12px;
    }

.chat-right-row p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

/* payout page */

.payout-groups-wrapper {
  margin-top: 24px;
  max-width: 1030px;
}

.payout-group {}

.payout-group .content-table {
  margin-top: 0;
}

.payout-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  border-bottom: 0.5px solid #ECECEC;
}

.payout-date {
  margin-right: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.payout-cost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 115px;
  padding: 9px 0 6px 0;
  box-sizing: border-box;
  background-color: #f9f5f1;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.payout-cost-bottom {
  margin-top: 4px;
  font-size: 10px;
  line-height: 12px;
  color: rgba(34, 34, 34, 0.5);
  /* opacity: 0.5; */
}

.payout-cost-bottom .payout-tooltip {
  vertical-align: middle;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: text-bottom;
  background-image: url(../img/svg/InfoCircleTransparentBg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

.payout-arrow {
    position: relative;
    align-self: center;
    display: inline-flex;
    justify-content: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
    width: 45px;
    cursor: pointer;
}

.payout-arrow::before {
  content: '';
  /* position: absolute; */
  /* right: 6px; */
  /* top: calc(50% - 4px); */
  display: inline-block;
  width: 8px;
  height: 7px;
  background-image: url(../img/svg/Arrow\ -\ Down\ 2.svg);
  background-size: 8px 7px;
  background-repeat: no-repeat;
  transition: transform .3s ease-out;
}

.payout-group-active .payout-arrow::before {
  transform: rotate(180deg);
}

.payout-group .content-table tbody tr td {
  vertical-align: middle;
}

.payout-table-cost {
  width: 160px;
}

.payout-empty {
  margin-top: 72px;
  text-align: center;
}

.payout-empty h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #222222;
  opacity: 0.4;
}

    .payout-empty .button {
        margin-top: 32px;
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
    }

.payout-table-bonus {}

.payout-col-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  min-width: 150px;
}

.payout-date {
  margin-right: 40px;
}

.payout-col-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* flex-grow: 1; */
    margin-left: auto;
}

    .payout-col-right.align-start {
        /* justify-content: flex-start; */
    }

.receipt-col {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 12px;
    min-width: 330px;
    max-width: 330px;
}

.receipt-col .receipt-tooltip {
  margin-left: 8px;
}

.payout-attach {
  margin-left: 12px;
}

.receipt-col input {
  width: 156px;
}

.payout-check {
    padding-left: 28px;
    font-weight: 500;
    font-size: 12px;
    color: #404040;
    min-height: 16px;
    background-size: 16px 16px;
    background-position: left center;
    background-repeat: no-repeat;
}

.payout-check-fail {
    background-image: url(../img/svg/CloseSquare.svg);
}

.payout-check-success {
    background-image: url(../img/svg/TickSquare.svg);
}

.payout-check-process {
    background-image: url(../img/svg/MoreSquare.svg);
}

.payout-check-links {
    display: flex;
}

.payout-check-link {
    margin-left: 24px;
    font-size: 12px;
}

.notification-wrapper{
    position: fixed;
    bottom: 90px;
    right: 10px;
    width: 300px;
    z-index: 10000;
}
.notification-item {
    box-shadow: 0 0 5px rgba(0,0,0, 0.4);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    width: 300px;
    animation: SlideLeft 0.5s;
    animation-fill-mode: forwards;
}

@keyframes SlideLeft{
    0%      { margin-left: 120%; }
    100%    { margin-left: 0; }
}

.notification-item.exit {
    animation: SlideRight 0.5s;
    animation-fill-mode: forwards;
}

@keyframes SlideRight {
    0%      { margin-left: 0; }
    100%    { margin-left: 120%; }
}

    .notification-item.success {
        background-color: rgba(255, 255, 255, 0.9);
    }

    .notification-item.error {
        background-color: rgba(255, 204, 204, 0.9);
    }

    .notification-item p {
        padding: 10px 0 10px 0;
        margin-left: 10px;
        cursor: default;
    }

    .notification-item .bar {
        height: 6px;
    }

.notification-item.success .bar {
    background-color: var(--color-orange);
    opacity: 0.5;
}
    .notification-item.error .bar {
        background-color: rgba(255, 153, 153, 1);
    }
	
/* datepicker */


.text-underline {
    text-decoration-line: underline;
    text-decoration-color: grey;
}

.tooltip {
    border: 1px solid rgba(224, 224, 224, 1) !important;
    opacity: 1 !important;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 0px;
    max-width: 427px;
    line-height: 17px;
    overflow: hidden;
    padding-right: 10px;
}

/* work rules */
.work-rules-content .num-list {
    max-width: 1012px;
}

ol {
    padding-left: 0;
}

.num-list {
    list-style-type: none;
    counter-reset: num;
}

    .num-list > li {
        margin-top: 16px;
        padding-left: 16px;
        position: relative;
    }

        .num-list > li::before {
            content: counter(num) '.';
            font-family: Lato;
            counter-increment: num;
            font-weight: 800;
            position: absolute;
            top: -2px;
            left: -3px;
        }

    .num-list li {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: #404040;
    }

    .num-list > li:first-of-type {
        margin-top: 0;
    }

.cyrilic-list {
    list-style: none;
}

    .cyrilic-list > li {
        padding-left: 16px;
        position: relative;
        list-style: none;
    }

        .cyrilic-list > li::before {
            content: '';
            font-weight: 500;
            position: absolute;
            top: 0;
            left: 0;
        }

        .cyrilic-list > li:nth-child(1)::before {
            content: 'а.';
        }

        .cyrilic-list > li:nth-child(2)::before {
            content: 'б.';
        }

        .cyrilic-list > li:nth-child(3)::before {
            content: 'в.';
        }

        .cyrilic-list > li:nth-child(4)::before {
            content: 'г.';
        }

        .cyrilic-list > li:nth-child(5)::before {
            content: 'д.';
        }

.work-rules-content .info-box {
    max-width: 838px;
    margin-top: 32px;
    margin-bottom: 48px;
    padding-right: 72px;
}

.num-list > li > ul {
    margin-top: 8px;
    padding-left: 16px;
}

.work-rules-content .quality-control {
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: 800;
}

.work-rules-content p {
    font-weight: 500;
}

/* receip modal */

.modal-receip {
}

    .modal-receip p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #4F4F4F;
        margin-top: 4px;
    }

.receip-row {
    display: flex;
    margin-bottom: 24px;
}

.receip-col-left {
    max-width: 242px;
    margin-right: 96px;
}

    .receip-col-left .receip-img {
        display: flex;
        margin-top: 40px;
        width: 100%;
        border: 4px solid #BDBDBD;
        box-sizing: border-box;
        filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
        border-radius: 4px;
        overflow: hidden;
        position: relative;
    }

        .receip-col-left .receip-img img {
            width: 100%;
        }

.receip-col-right {
    max-width: 400px;
    flex-grow: 1;
}

.receip-col-center{
    margin-left: auto;
    margin-right: auto;
}

.receip-form {
    margin-top: 14px;
}

    .receip-form .form-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .receip-form .form-item > label {
            min-width: 86px;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 24px;
            color: #4F4F4F;
            margin-right: 16px;
            flex-grow: 1;
        }

        .receip-form .form-item input {
            max-width: 298px;
        }

        .receip-form .form-item input {
            height: 28px;
            padding: 2px 12px;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 2px;
            font-size: 14px;
            line-height: 24px;
            color: #333333;
        }

        .receip-form .form-item button {
            margin-left: 12px;
        }

.form-item-error label {
    color: #EA2828 !important;
}

.form-item-error input {
    border-color: #EA2828 !important;
}

.receip-step-2 {
    margin-top: 100px;
}

/* checkConditions modal */

.modal-order-checkConditions {
    font-weight: 600;
}

.modal-order-checkConditions ul li {
    margin-top: 4px;
}

.modal-order-checkConditions b {
    font-weight: 800;
}

/* bug report*/

.form-item .bug-report-text{
    min-height: 250px;
    max-width: 100%;
}

/* moneybox */
.moneybox-calc-mob {
}

.moneybox-calc-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
    min-height: 28px;
}

.moneybox-calc-mob .moneybox-calc-row:not(:first-child) {
    margin-top: 13px;
}

.moneybox-calc-row-left {
    font-size: 12px;
    line-height: 14px;
    color: #222222;
    opacity: 0.8;
}

.moneybox-calc-row-right {
    display: flex;
    font-size: 12px;
    color: rgba(34, 34, 34, 0.8);
}

.moneybox-calc-row-right-margin {
    min-width: 30px;
    text-align: end;
}

.moneybox-calc-count {
    max-width: 56px;
}

.moneybox-calc-row-res {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    margin: 0;
    padding: 8px 0;
    box-sizing: border-box;
}

    .moneybox-calc-row-res .moneybox-calc-row-left, .moneybox-calc-row-res .moneybox-calc-row-right {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
        color: #404040;
    }

h2.moneybox-table-title {
    margin-top: 55px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: #404040;
}

.moneybox-table-row {
    margin-top: 24px;
    display: flex;
}

.moneybox-table {
    width: 33.33%;
    margin-top: 0;
}

.moneybox-table-row .moneybox-table thead tr:first-child td:first-child {
    border-top-left-radius: 0;
}

.moneybox-table-row .moneybox-table thead tr:first-child td:first-child {
    border-top-left-radius: 0;
}

.moneybox-table-row .moneybox-table thead tr:first-child td:last-child {
    border-top-right-radius: 0;
}

.moneybox-table-row .moneybox-table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0;
}

.moneybox-table-row .moneybox-table thead tr:last-child td:last-child {
    border-bottom-right-radius: 0;
}

.moneybox-table-row .moneybox-table:first-child thead tr:last-child td:first-child {
    border-top-left-radius: 8px;
}

.moneybox-table-row .moneybox-table:first-child thead tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.moneybox-table-row .moneybox-table:last-child thead tr:last-child td:last-child {
    border-top-right-radius: 8px;
}

.moneybox-table-row .moneybox-table:last-child thead tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.moneybox-table-row .moneybox-table:not(:last-child) tbody tr td:last-child {
    border-right: 1px solid rgba(196, 196, 196, 0.3);
}

.moneybox-table-row .moneybox-table {
    border-bottom: 1px solid rgba(196, 196, 196, 0.3);
}

    .moneybox-table-row .moneybox-table thead tr td:last-child, .moneybox-table-row .moneybox-table tbody tr td:last-child {
        text-align: center;
    }

    .moneybox-table-row .moneybox-table tbody tr:first-child td {
        padding-top: 15px;
    }

    .moneybox-table-row .moneybox-table tbody tr:last-child td {
        padding-bottom: 15px;
    }

.lineheight24 {
    line-height: 24px;
}

/*suggested-list*/

.pointer{
    cursor: pointer;
}

.antiplagius-instruction-screenshot{
    height: 320px;
}

.feedback-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
}

.tutorial-image {
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: #f0f0f0; 
    width: 100%; 
    padding: 20px; 
    box-sizing: border-box; 
    margin: 0 auto; 
}

    .tutorial-image img {
        display: block; 
        max-width: 95%; 
        height: auto; 
    }


/* Если ширина экрана меньше или равна 768px, изображения растягиваются до 100% ширины контейнера */
@media (max-width: 768px) {
    .tutorial-image {
        max-width: 100%;
        height: auto;
    }
}

img.homely-raccoon{
    width: 100px;
}

.feedback-content{
    display: flex;
}

.writer-raccoon{
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
}

.feedback-fields{
    width: 100%;
}

.tutorial-video-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4em;
}

    .tutorial-video-list ul li {
        list-style: disc;
    }

.tutorial-video {
    width: 560px;
    margin-bottom: 20px;
}

    .tutorial-video iframe {
        width: 100%;
        background: #a0a0a0;
    }

.tutorial-video-info {
    padding: 10px;
    width: 450px;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;800&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    padding: 0;
}

.main-wrapper .menu-and-content {
    display: flex;
    position: relative;
}

.main-wrapper .backdrop.-active {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 1081px) {
    .main-wrapper .backdrop {
        display: none;
    }
}

.link {
    color: var(--blue-2);
    text-decoration: none;
}

    .link:hover {
        text-decoration: underline;
        cursor: pointer;
    }