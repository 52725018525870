@media all and (min-width: 1400px) {
	.header-menu-group.search-active {
		display: flex;
	}

	.saved-students.search-active {
		display: flex;
	}
}

@media all and (max-width: 1200px) {
	.header-nav {
		padding-right: 24px;
	}

	.header-account {
		margin-left: 20px;
		margin-right: 20px;
	}

	.form-row {
		margin-left: -16px;
		margin-right: -16px;
	}

	.form-row .form-item {
		max-width: 50%;
		padding-left: 16px;
		padding-right: 16px;
	}

	.profile-account-info {
		margin-left: 32px;
	}

	.tooltip {
		max-width: 300px;
	}

	.header-search {
		margin-right: 24px;
	}

	.header-logout {
		margin-left: 0;
	}
}

@media all and (max-width: 1080px) {
	.main-header {
		left: 0;
	}

	.header-nav {
		padding-left: 24px;
		padding-right: 0;
	}

	.header-burger {
		display: block;
		flex-grow: unset;
		margin-right: 24px;
	}

	.header-logout {
		display: none;
	}

	/*sidebar*/
	.sidebar {
		position: fixed;
		left: -286px;
		transition: transform 0.3s ease-out;
		padding-bottom: 60px;
	}

	.sidebar.active {
		transform: translateX(286px);
	}

	.sidebar::-webkit-scrollbar {
		width: 5px;
	}

	.sidebar::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: RGBA(0, 0, 0, 0.15);
	}

	.sidebar-logo {
		justify-content: space-between;
		padding: 0 16px;
		/* height: auto; */
	}

	.logo {
		width: 115px;
	}

	.sidebar-logo .header-burger {
		margin: 0;
	}

	.sidebar-logout {
		display: block;
		font-size: 14px;
	}

	/*main*/
	.main-wrapper {
		padding-left: 0;
	}

	.content {
		/*        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 40px;*/
	}

	/* profile */
	.profile-payment-row {
		margin-top: 40px;
		flex-wrap: wrap;
	}

	.profile-category-info {
		width: 100%;
	}

	.profile-account-info {
		width: 100%;
		margin-left: 0;
		margin-top: 32px;
	}

	.tooltip {
		max-width: 300px;
	}

	/* receip modal */
	.receip-col-left {
		margin-right: 60px;
	}
}

@media all and (max-width: 768px) {
	.desktop-only {
		display: none;
	}

	.tablet-only {
		display: block;
	}

	.tablet-hidden {
		display: none;
	}

	/* main */
	.main-wrapper {
		padding-top: 0;
	}

	/* header */
	.main-header {
		position: relative;
		left: 0;
		right: 0;
		top: 0;
		min-height: 98px;
	}

	.header-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 20px;
	}

	.header-search {
		order: 1;
		flex-grow: unset;
		margin-right: 0;
	}

	.header-search-btn {
		display: inline-flex;
		cursor: pointer;
	}

	.search-control {
		justify-content: flex-end;
	}

	.saved-students.search-active {
		display: flex;
	}

	.header-menu-group {
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: unset;
		width: 50%;
		margin-top: 16px;
		padding: 24px 0;
		border-right: none;
		order: 3;
		border-top: 1px solid #e0e0e0;
		/*border-bottom: 1px solid #E0E0E0;*/
	}

	.header-menu-group.search-active {
		display: flex;
	}

	.header-menu-group > a {
		position: relative;
		margin: 0 24px;
	}

	.header-nav .header-menu-group:nth-child(odd) {
		justify-content: flex-start;
	}

	.header-nav .header-menu-group:nth-child(even) {
		justify-content: flex-end;
	}

	.header-account {
		flex-direction: column;
		order: 2;
		width: 100%;
		margin-top: 8px;
	}

	.header-account > a {
		margin-bottom: 12px;
	}

	.account-type {
		width: 56px;
		height: 20px;
		margin-top: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		font-weight: 600;
		font-size: 10px;
		line-height: 12px;
		order: 2;
	}

	.account-title {
		text-align-last: center;
		margin-left: 0;
	}

	.account-status {
		font-size: 12px;
		line-height: 20px;
	}

	.account-name {
		text-align: center;
	}
	/* sidebar */
	.sidebar-logo {
		justify-content: space-between;
		padding: 24px 16px;
		height: auto;
	}

	.content-table tbody tr td {
		min-height: 40px;
		padding: 12px 12px;
	}

	.payout-popover {
		position: absolute;
		top: 222px;
		left: 0;
		width: 100%;
	}

	.tooltip {
		max-width: 200px;
	}

	.work-rules-content .info-box {
		padding-right: 32px;
	}

	/* modal receip */
	.modal-receip {
		max-width: 669px;
		padding-left: 24px;
		padding-right: 24px;
	}

	.receip-col-left {
		max-width: 214px;
		margin-right: 32px;
	}

	.receip-col-right {
		display: flex;
		flex-direction: column;
	}

	.receip-form {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.receip-step-2 {
		margin-top: unset;
	}

	/* moneybox */
	h2.moneybox-table-title {
		margin-top: 32px;
	}

	.moneybox-table-row {
		flex-direction: column;
	}

	.moneybox-table {
		width: 100%;
	}

	.moneybox-table-row .moneybox-table thead tr:first-child td:last-child {
		border-top-right-radius: 8px;
	}

	.moneybox-table-row .moneybox-table thead tr:first-child td:last-child {
		border-bottom-right-radius: 8px;
	}

	.moneybox-table-row .moneybox-table thead tr td:last-child,
	.moneybox-table-row .moneybox-table tbody tr td:last-child {
		width: 120px;
	}

	.moneybox-table-row .moneybox-table:not(:last-child) tbody tr td:last-child {
		border-right: none;
	}

	.moneybox-table-row .moneybox-table {
		border-bottom: none;
	}

	.moneybox-table-row .moneybox-table:not(:first-child) thead {
		display: none;
	}

	.chat-slider-nav {
		display: flex;
		z-index: 100;
	}

	.chat-right-col {
		display: none;
	}

	/* payout page */

	.payout-groups-wrapper {
		margin-left: -24px;
		margin-right: -24px;
	}

	.payout-title {
		padding-left: 0;
		align-items: flex-start;
		border-bottom: none;
		background-color: #f9f5f1;
		flex-wrap: wrap;
	}

	.payout-col-left {
		padding-left: 16px;
		min-height: 40px;
		max-width: 150px;
	}

	.payout-col-right {
	}

	.payout-date {
		padding-top: 9px;
		margin-right: 0;
	}

	.payout-arrow {
		position: relative;
		align-self: stretch;
		align-items: center;
		width: 43px;
	}

	.payout-arrow::before {
		right: 20px;
	}

	.payout-table-cost {
		width: unset;
	}

	.payout-empty {
		margin-top: 34px;
	}

	.payout-empty h3 {
		font-size: 16px;
		line-height: 20px;
	}

	.payout-empty .button {
		margin-top: 24px;
	}

	.receipt-col {
		background: #ffffff;
		padding-top: 8px;
		padding-bottom: 40px;
		padding-left: 16px;
		padding-right: 16px;
		margin-right: 0;
		margin-left: 0;
		min-width: unset;
		max-width: unset;
		width: 100%;
		flex-wrap: wrap;
	}

	.receipt-col input {
		max-width: unset;
		width: calc(100% - 32px);
	}

	.receipt-col .receipt-tooltip {
		margin-left: auto;
	}

	.payout-attach {
		margin-left: 0;
		margin-top: 20px;
		width: 100%;
	}

	.payout-attach .button {
		width: 100%;
	}

	.payout-check {
		display: inline-flex;
		align-items: center;
		width: 100%;
	}

	.payout-check a {
		margin: 0 4px;
	}

	.payout-check-links {
		margin-top: 20px;
		width: 100%;
		margin-left: -8px;
		margin-right: -8px;
		display: flex;
	}

	.payout-check-link {
		flex-grow: 1;
		margin-left: 8px;
		margin-right: 8px;
		border: 1px solid rgba(34, 34, 34, 1);
		box-sizing: border-box;
		border-radius: 4px;
		height: 32px;
		font-weight: 500;
		font-size: 12px;
		text-transform: unset;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.chat-header {
		padding: 5px 30px 10px 30px;
		max-height: 210px;
	}

	.chat-header-space {
		display: block;
		height: 35px;
	}

	.scroll-control {
		right: 20px;
		top: 20px;
	}

	.content-table .status-column {
		min-width: 0;
		max-width: 0;
	}

	.content-table .theme-column {
		max-width: 0;
	}

	.content-table .type-column {
		min-width: 0;
		max-width: 0;
	}

	.content-table .deadline-column {
		min-width: 0;
		max-width: 0;
	}

	.content-table .price-column {
		min-width: 0;
		max-width: 0;
	}

	.writer-raccoon {
		display: none;
	}

	/* orders list */
	/*
    .filters-wrapper {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }

    .filter-item {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 16px;
    }

    .filters-wrapper .filter-item:first-of-type {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }

    .filter-select {
        max-width: 100%;
        width: 100%;
        margin-top: 10px;
    }

    .filter-item.radio-group {
    }

        .filter-item.radio-group > .radio-item {
            margin-right: 16px;
        }

    .filter-item .button {
        width: 100%;
    }
    */
}

@media all and (max-width: 480px) {
	.desktop-only {
		display: none;
	}

	.tablet-only {
		display: none;
	}

	.mobile-only {
		display: block;
	}

	.mobile-hidden {
		display: none;
	}

	/* modal */

	.modal-wrapper {
		align-items: flex-start;
		padding: 20px 0;
	}

	.modal {
		padding: 30px 20px 40px 20px;
	}

	.button-group {
		margin-left: 0;
		margin-right: 0;
	}

	.button-group .button {
		margin: 0;
		margin-bottom: 16px;
		width: 100%;
	}

	.button-group .specialities-button {
		margin-top: 16px;
		width: 100%;
	}

	.modal-signup .modal-title {
		text-align: left;
	}

	.form-hr {
		margin-left: -20px;
		margin-right: -20px;
	}

	.start-page {
		position: relative;
		padding-top: 60px;
		box-sizing: border-box;
		background-image: url(../img/login-bg-mob.png);
	}

	.start-page .logo-wrapper {
		/* display: inline-block;
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto; */
		position: absolute;
		top: 24px;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
	}

	.start-page .logo {
		display: inline-block;
		width: 144px;
	}

	.start-page .logo img {
		width: 100%;
	}

	.start-page h1 {
		font-size: 32px;
		line-height: 42px;
	}

	.start-page h2 {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 28px;
	}

	.start-page h1,
	.start-page h2 {
		max-width: 230px;
	}

	.start-page .modal {
		box-shadow: none;
		background-color: transparent;
	}
	/* forgot password */
	.modal-forgot-success .modal-subtitle {
		font-size: 16px;
		line-height: 28px;
		max-width: 270px;
	}

	.modal-forgot-success .form-forgot-email {
		margin-top: 32px;
		margin-bottom: 40px;
	}

	.modal-forgot-success .form-redirect {
		margin-top: 32px;
		margin-left: auto;
		margin-right: auto;
		max-width: 275px;
	}
	/* author category, account types */
	.money-rule-item {
		width: 50%;
		padding-left: 16px;
		padding-right: 16px;
	}
	/* account form modal */
	.account-steps-wrapper {
		margin-left: -16px;
		margin-right: -16px;
		padding: 16px 16px 14px 16px;
	}

	.account-step {
		width: 100%;
		padding: 0;
	}

	.account-step:not(.account-step-active) {
		display: none;
	}

	.account-form-items .account-from-group {
		display: flex;
		flex-wrap: wrap;
	}

	.account-form-items .account-from-group input {
		width: 100%;
		margin-right: 0;
		margin-bottom: 16px;
	}

	.account-form-items .account-from-group button {
		width: 100%;
	}

	.account-form-buttons {
		display: flex;
		margin-left: -8px;
		margin-right: -8px;
		justify-content: space-between;
	}

	.account-form-buttons .button {
		width: 50%;
		margin: 0 8px;
	}

	/* order page */
	.content .hr {
		margin: 16px 0;
	}

	.order-descr {
		display: flex;
		flex-direction: column;
	}

	.order-descr-item {
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-top: 12px;
		padding-bottom: 12px;
		display: flex;
		justify-content: space-between;
	}

	.order-descr-item:not(:last-of-type) {
		border-bottom: 1px solid rgba(224, 224, 224, 0.4);
	}

	.order-descr-item .header-icon {
		display: none;
	}

	.order-descr-item .order-param {
		margin-bottom: 0;
		text-align: left;
	}

	.order-descr-item p {
		text-align: right;
	}

	.order-param .icon-question {
		margin-left: 7px;
		vertical-align: text-bottom;
		cursor: pointer;
	}

	.order-file-edited {
		flex-wrap: wrap;
	}

	.order-file-edited input {
		width: 100%;
		max-width: 100%;
	}

	.order-file-edited .button {
		margin-top: 12px;
		max-width: 50%;
		flex-grow: 1;
	}

	.order-buttons {
		margin-left: 0;
		margin-right: 0;
	}

	.order-buttons .button {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 16px;
		width: 100%;
		min-height: 39px;
	}
	/* final version modal */
	.final-version-row {
		flex-wrap: wrap;
	}

	.final-version-row > .final-version-col:first-child {
		margin-right: 0;
		margin-bottom: 16px;
	}

	.final-version-row > .final-version-col:last-child {
		max-width: 500px;
	}

	/* subjects */
	.form-row .form-item {
		max-width: 100%;
	}

	.form-row .subject-item {
		margin-bottom: 0;
		margin-top: 16px;
	}
	/*
     payout page 
    .payout-groups-wrapper {
        margin-left: -24px;
        margin-right: -24px;
    }

    .payout-title {
        align-items: flex-start;
        padding-left: 1vmin;
        border-bottom: none;
        background-color: #f9f5f1;
    }

    .payout-date {
        padding-top: 9px;
    }

    .payout-arrow {
        position: relative;
        align-self: center;
        width: 43px;
    }

        .payout-arrow::before {
            right: 20px;
        }

    .payout-table-cost {
        width: unset;
    }

    .payout-empty {
        margin-top: 34px;
    }

        .payout-empty h3 {
            font-size: 16px;
            line-height: 20px;
        }

        .payout-empty .button {
            margin-top: 24px;
        }
    */
	.tooltip {
		max-width: 200px;
	}

	/* receip modal */
	.modal-receip {
		padding-bottom: 80px;
	}

	.receip-row {
		flex-direction: column;
		margin-bottom: 8px;
	}

	.receip-col-left {
		text-align: center;
	}

	.receip-col-left .receip-img {
		margin-top: 8px;
	}

	.receip-col-right {
		margin-top: 40px;
	}

	.receip-form .form-item {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 16px;
	}

	.receip-form .form-item > label {
		margin-bottom: 4px;
		margin-right: 0;
	}

	.receip-form .form-item button {
		margin-top: 12px;
		margin-left: auto;
		margin-right: auto;
	}

	.receip-step-2 {
		margin-top: 16px;
	}

	/*chat*/

	.chat-menu-count {
		margin-left: 10px;
	}

	.chat-orders-wrapper {
		width: auto;
	}

	.modal-chat-row {
		display: block;
	}

	.chat-header {
		padding: 5px 30px 20px 30px;
		max-height: 200px;
	}

	.chat-header-space {
		display: block;
		height: 40px;
	}
}
