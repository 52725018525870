:root {
	--white: #fff;
	--black-1: #1f1f1f;
	--black-2: #151515;
	--green-1: #15a06e;
	--green-2: #30c58f;
	--blue-1: #91acd1;
	--blue-2: #3965ff;
	--blue-3: #f2f7ff;
	--blue-4: #378cbd;
	--blue-5: #fbfcff;
	--blue-6: #dbe9ff;
	--blue-7: #f5f9fc;
	--blue-8: #1c47df;
	--blue-9: #5e80b7;
	--blue-10: #edf6fc;
	--blue-11: #d6e4eb;
	--red-1: #ea2828;
	--red-2: #dd4141;
	--orange-1: #ff922e;
	--gray-1: #e0e0e0;
	--gray-2: #f3f3f3;
	--gray-3: #f9f9f9;
	--gray-4: #8d8d8d;
	--gray-5: #e7ecf1;
	--gray-6: #52565c;
	--gray-7: #dddddd;
	--gray-8: #98a2b0;
	--gray-9: #e0e0ec;
	--gray-10: #f4f4f4;
	--brown-1: #fffdf5;
	--brown-2: #f9f1d0;
	--brown-3: #fffaf9;
	--brown-4: #fafafa;
	--brown-5: #ffeede;
	--brown-6: #fff7f2;
}
